import './App.css'

function Footer(){
    return(
        <div>
            <h2 className='footer-text'>Built From Scratch With 💜 and React</h2>
        </div>
    )
}

export default Footer